<template>
    <main>
        <div id="login-form--wrapper">
            <div class="column-1">
                <div class="ui basic segment container">
                    <router-link :to="{ name: 'Home' }"><img class="logo" src="~@/assets/images/logo/1991spwg.png"/></router-link>
                    <form class="ui underlined form" :class="{loading: submitting}" action="javascript:void(0);" @submit.prevent="submit" v-if="authorized">
                        <div class="ui hidden section divider"></div>
                        <h1 class="ui poppins weight-400 header" style="font-size: 3rem;">Update Your Password</h1>
                        <p class="ui grey text">Change your password to a new one.</p>
                        <div class="ui hidden section divider"></div>
                        <div class="field">
                            <label class="ui grey text">New Password</label>
                            <div class="ui icon input">
                                <input :type="inputs.new_password.visible? 'text' : 'password'" v-model.trim="inputs.new_password.value" @keyup="checkCapsLock($event, inputs.new_password)" @focus="checkCapsLock($event, inputs.new_password)" @mousedown="checkCapsLock($event, inputs.new_password)" @blur="removeCapsLockedClass(inputs.new_password)" autocomplete="new-password" required/>
                                <i class="inverted black link icon" :class="{'eye': !inputs.new_password.visible, 'eye slash': inputs.new_password.visible}"  @click="togglePasswordVisibility(inputs.new_password)"></i>
                            </div>
                            <div class="ui red warning text" v-show="inputs.new_password.caps_locked"><i class="exclamation circle icon"></i> Caps Lock is on</div>
                        </div>
                        <div class="ui hidden divider"></div>
                        <div class="field">
                            <label class="ui grey text">Confirm New Password</label>
                            <div class="ui icon input">
                                <input :type="inputs.confirm_password.visible? 'text' : 'password'" v-model.trim="inputs.confirm_password.value" @keyup="checkCapsLock($event, inputs.confirm_password)" @focus="checkCapsLock($event, inputs.confirm_password)" @mousedown="checkCapsLock($event, inputs.confirm_password)" @blur="removeCapsLockedClass(inputs.confirm_password)" autocomplete="new-password" required/>
                                <i class="inverted black link icon" :class="{'eye': !inputs.confirm_password.visible, 'eye slash': inputs.confirm_password.visible}"  @click="togglePasswordVisibility(inputs.confirm_password)"></i>
                            </div>
                            <div class="ui red warning text" v-show="inputs.confirm_password.caps_locked"><i class="exclamation circle icon"></i> Caps Lock is on</div>
                        </div>
                        <div class="ui hidden divider"></div>
                        <div class="ui right aligned text">
                            <button type="submit" class="ui black button">Update Password</button>
                        </div>
                        <div class="ui hidden section divider"></div>
                        <div class="ui hidden section divider"></div>
                    </form>
                    <div v-else>
                        <div class="ui hidden section divider"></div>
                        <h1 class="ui poppins weight-400 header" style="font-size: 3rem;">401 Unauthorized</h1>
                        <p class="ui grey text">You are not authorized on this page.</p>
                        <p><router-link :to="{name: 'Login'}">Go to Login page</router-link></p>
                        <div class="ui hidden section divider"></div>
                        <div class="ui hidden section divider"></div>
                    </div>
                </div>
            </div>
            <div class="column-2">

            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            token: this.$route.params?.token || "",
            email: this.$route.params?.username || "",
            password: this.$route.params?.password || "",
            inputs: {
                new_password: {
                    visible: false,
                    caps_locked: false,
                    value: ""
                },
                confirm_password: {
                    visible: false,
                    caps_locked: false,
                    value: ""
                }
            },
            submitting: false
        };
    },
    computed: {
        authorized() {
            return (this.token && this.email && this.password);
        }
    },
    methods: {
        submit() {
            this.submitting = true;

            try {
                if(this.inputs.new_password.value !== this.inputs.confirm_password.value) {
                    throw new Error("Please confirm on your password again.");
                }

                this.$http.put("users/password", {
                    password: this.password,
                    new_password: this.inputs.new_password.value,
                    is_temp: true
                }, {
                    headers: {
                        "Authorization": `Bearer ${this.token}`,
                        "x-session-token": this.token
                    }
                }).then((response) => {
                    this.$toasted.success("Password updated succesfully. Log in using new password now.", {
                        duration: 3000,
                        action: {
                            text: "OK",
                            onClick: (e, toast) => {
                                this.$router.push({
                                    name: "Login"
                                });

                                toast.goAway(0);
                            }
                        },
                        onComplete: () => {
                            this.$router.push({
                                name: "Login"
                            });
                        }
                    });
                }).catch((error) => {
                    this.submitting = false;

                    console.error(error);
                    this.$toasted.error("Unable to update new password.", {
                        duration: 3000
                    });
                });
            } catch(error) {
                this.submitting = false;
                console.error(error);

                this.$toasted.show(error.message, {
                    duration: 3000,
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            toast.goAway(0);
                        }
                    }
                });
            }
        },
        togglePasswordVisibility(input) {
            input.visible = !input.visible;
        },
        removeCapsLockedClass(input) {
            input.caps_locked = false;
        },
        checkCapsLock(event, input) {
            try {
                input.caps_locked = !!event.getModifierState("CapsLock");
            } catch(error) {
            }
        }
    }
};
</script>

<style lang="scss" scoped>
main {
    #login-form--wrapper {
        display: flex;
        align-items: stretch;

        .column-1 {
            background: #fff;
            width: 38.2%;
            flex: 0 0 38.2%;
            display: flex;
            align-items: center;
            justify-content: center;
             overflow: hidden;

            .ui.form {
                align-self: stretch;
                animation: animation-slideUp ease-out 300ms;
            }
        }

        .column-2 {
            width: 61.8%;
            flex: 0 0 61.8%;
            background: #fff url('~@/assets/images/background/login.min.jpg') no-repeat center center/cover;
        }

        .logo {
            max-width: 90%;
            width: 200px;
        }
    }

    @media only screen and (min-width: 1025px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        #login-form--wrapper {
            max-width: 1024px;
            box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
        }
    }

    @media only screen and (max-width: 1024px) {
        #login-form--wrapper {
            min-height: 100vh;
        }
    }

    @media only screen and (max-width: 1023px) {
        #login-form--wrapper {
            position: relative;
            align-items: center;
            justify-content: center;

            .logo {
                margin-top: -3rem;
            }

            .column-1 {
                flex: 0 0 auto;
                width: 500px;
                max-width: 100%;
                background: none;
            }

            .column-2 {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        background: url('~@/assets/images/background/login-main.jpg') no-repeat center center/cover,
                    linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.9) 100%);
        background-blend-mode: saturation;

        #login-form--wrapper {
            .column-1 {
                padding: 3rem;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        #login-form--wrapper {
            .column-1 {
                padding-top: 3rem;
                padding-bottom: 3rem;

                > .ui.segment.container {
                    width: 100% !important;
                }
            }
        }
    }
}
</style>